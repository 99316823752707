import React, { useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/sharp-regular-svg-icons/faArrowRightLong";
import { faXmark } from "@fortawesome/sharp-regular-svg-icons/faXmark";
import clsx from "clsx";
import Balancer from "react-wrap-balancer";

import { commonStackedGrid } from "~styles/common/common.stacked-grid.css";
import { type GetSprinklesArgs, getSprinkles } from "~styles/getSprinkles.css";

import { Box } from "~components/Box";
import { Breadcrumbs } from "~components/Breadcrumbs";
import { ButtonLink } from "~components/ButtonLink";
import { ContentAuthorDate } from "~components/ContentAuthorDate";
import { EmbedWistia } from "~components/EmbedWistia";
import { FormHubspotDialog } from "~components/FormHubspotDialog";
import { Image } from "~components/Image";
import { StoryblokEditable } from "~components/StoryblokEditable";
import { StoryblokRichText } from "~components/StoryblokRichText";
import { SvgLogo } from "~components/SvgLogo";
import { Video } from "~components/Video";

import * as styles from "./styles.css";
import { dateStyle, getSectionHeaderStyles, offsetStyle } from "./styles.css";

import type { PointerEventHandler } from "react";
import type { BoxProps } from "~components/Box";
import type { BreadcrumbsProps } from "~components/Breadcrumbs";
import type { ButtonLinkProps } from "~components/ButtonLink";
import type { EmbedWistiaProps } from "~components/EmbedWistia";
import type { FormHubspotDialogProps } from "~components/FormHubspotDialog";
import type { SvgLogoProps } from "~components/SvgLogo";
import type { VideoProps } from "~components/Video";
import type { varsClipPathInset } from "~styles/vars/varsClipPathInset.css";
import type {
  AuthorEntry,
  StoryblokBlok,
  StoryblokFieldMedia,
  StoryblokFieldRichText,
} from "~types/storyblok.types";

export type SeoHeaderTags =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | ""
  | undefined;

export interface SectionHeaderProps
  extends Omit<BoxProps, "title" | "textAlign"> {
  breadcrumbs?: BreadcrumbsProps["breadcrumbs"];
  buttonLink?: Array<
    StoryblokBlok & (ButtonLinkProps | FormHubspotDialogProps)
  >;
  description?: StoryblokFieldRichText | string;
  hasPadding?: boolean;
  isCentered?: boolean;
  isInline?: boolean;
  prefix?: string;
  prefixTag?: SeoHeaderTags;
  prefixLink?: Array<
    StoryblokBlok & (ButtonLinkProps | FormHubspotDialogProps)
  >;
  textAppearance?: Extract<
    GetSprinklesArgs["textAppearance"],
    "h0" | "h1" | "h2" | "h3" | "h4"
  >;
  title?: string;
  titleTag?: SeoHeaderTags;
  image?: StoryblokFieldMedia | null;
  video?: Array<StoryblokBlok & VideoProps>;
  mask?: keyof typeof varsClipPathInset;
  date?: string;
  datePrefix?: string;
  author?: AuthorEntry;
  authorDate?: string;
  cover?: StoryblokFieldMedia;
  coverAspectRatio?: "wide" | "a4" | "";
  logo?: SvgLogoProps["svgLogo"];
  coverVideo?: Array<StoryblokBlok & EmbedWistiaProps>;
  isCoverVideoSticky?: boolean;
}

const BUTTON_PROPS = {
  iconRight: faArrowRightLong,
};

const DEFAULT_COVER_ASPECT_RATIO = "wide";

const getFlexProperties = (
  coverAspectRatio: SectionHeaderProps["coverAspectRatio"]
) => {
  switch (coverAspectRatio) {
    case "a4":
      return {
        infoFlex: 3,
        coverFlex: 1,
      };
    case "wide":
      return {
        infoFlex: 1,
        coverFlex: 1,
      };
    default:
      return {
        infoFlex: 1,
        coverFlex: 0,
      };
  }
};

/**
 * Renders a re-usable heading for a section combining a subscript title and
 * title.
 */
export function SectionHeader({
  breadcrumbs,
  buttonLink: buttonLinkBlokArray,
  className: userClassName,
  description,
  fontFamily,
  isCentered,
  isInline,
  maxWidth = "gridSpan8",
  prefix,
  prefixTag,
  textAppearance = "h3",
  title,
  titleTag,
  image,
  video: videoBlokArray,
  mask,
  date,
  datePrefix,
  prefixLink: prefixLinkBlokArray,
  author,
  authorDate,
  cover,
  coverAspectRatio: userCoverAspectRatio,
  logo,
  coverVideo: coverVideoBlokArray,
  isCoverVideoSticky = true,
  ...rest
}: SectionHeaderProps) {
  const coverAspectRatio = userCoverAspectRatio || DEFAULT_COVER_ASPECT_RATIO;
  const [coverVideo] = coverVideoBlokArray || [];
  const coverVideoContainerID = coverVideo
    ? `section-header-cover-video-container-${coverVideo?.mediaId}`
    : undefined;
  const [buttonLink] = buttonLinkBlokArray || [];
  const [videoBlok] = videoBlokArray || [];
  const [prefixLink] = prefixLinkBlokArray || [];
  const hasDescription = Boolean(
    description &&
      (typeof description === "string" ||
        description.content[0]?.content?.[0]?.text)
  );

  const { infoFlex, coverFlex } = getFlexProperties(coverAspectRatio);

  const elementRef = useRef<HTMLElement>(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isStickyEnabled, setIsStickyEnabled] = useState(isCoverVideoSticky);
  const [translate, setTranslate] = useState({
    x: 0,
    y: 0,
  });

  const closeStickyVideo = ({ disable = false }: { disable?: boolean }) => {
    if (disable) {
      setIsStickyEnabled(false);
    }
    setIsSticky(false);
    setTranslate({
      x: 0,
      y: 0,
    });
  };

  useEffect(() => {
    if (coverVideo) {
      const headerContainer = elementRef.current;

      const isCoverVideoPlaying = () => {
        const video = document.querySelector(
          `#${coverVideoContainerID} video`
        ) as HTMLVideoElement;

        if (!video) return false;

        return (
          video.currentTime > 0 &&
          !video.paused &&
          !video.ended &&
          video.readyState > 2
        );
      };
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (!entry || !coverVideo) {
            return;
          }
          const { isIntersecting } = entry;
          if (isIntersecting === isSticky) {
            const toggle = !isSticky;

            if (toggle === false) {
              closeStickyVideo({ disable: false });
            } else if (isStickyEnabled && isCoverVideoPlaying()) {
              setIsSticky(true);
            }
          }
        },
        {
          threshold: 0.2,
        }
      );

      if (headerContainer) {
        observer.observe(headerContainer);
      }

      return () => {
        if (headerContainer) {
          observer.unobserve(headerContainer);
        }
      };
    }

    return () => {};
  }, [isSticky, isStickyEnabled, coverVideo, coverVideoContainerID]);

  // Handle drag & drop
  const [isDragging, setIsDragging] = useState(false);
  const videoContainer = useRef<HTMLElement>(null);

  const isOutsideViewport = () => {
    if (!videoContainer.current || !isSticky) return false;

    const containerRect = videoContainer.current.getBoundingClientRect();
    const buffer = 1; // Distance from edge to consider as border

    return (
      containerRect.left <= buffer ||
      containerRect.top <= buffer ||
      window.innerWidth - containerRect.right <= buffer ||
      window.innerHeight - containerRect.bottom <= buffer
    );
  };

  const handlePointerDown = () => {
    if (!isSticky) return;
    setIsDragging(true);
  };

  const handlePointerUp = () => {
    if (!videoContainer.current || !isSticky) return;
    setIsDragging(false);

    if (isOutsideViewport()) {
      closeStickyVideo({ disable: true });
      videoContainer.current.style.opacity = "1";
    }
  };

  const handlePointerMove: PointerEventHandler<HTMLElement> = (e) => {
    if (!isDragging || !videoContainer.current || !isSticky) return;

    e.preventDefault();

    setTranslate({
      x: translate.x + e.movementX,
      y: translate.y + e.movementY,
    });

    if (isOutsideViewport()) {
      videoContainer.current.style.opacity = "0.5";
    } else {
      videoContainer.current.style.opacity = "1";
    }
  };

  const prefixLinkIsButtonLink = prefixLink?.component === "ButtonLink";
  const buttonLinkIsFormHubspotDialog =
    buttonLink?.component === "FormHubspotDialog";
  const isButtonLink = buttonLink?.component === "ButtonLink";

  return (
    <Box
      test-id="section-header"
      display="flex"
      flexWrap="wrap"
      width="100%"
      columnGap="spacing5"
      alignItems="center"
      rowGap="spacing3"
      ref={elementRef}
      flexDirection={{
        tablet: "column",
        desktop: "row",
      }}
    >
      <Box
        test-id="section-header-info-container"
        as="header"
        __flex={infoFlex}
        className={clsx(
          userClassName,
          getSectionHeaderStyles({ isCentered, isInline, hasDescription })
        )}
        {...rest}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={logo ? "none" : "spacing2"}
          width="auto"
        >
          {breadcrumbs && (
            <Breadcrumbs
              marginX={isCentered ? "auto" : undefined}
              breadcrumbs={breadcrumbs}
            />
          )}
          {logo && (
            <SvgLogo
              maxHeight="spacing12"
              svgLogo={logo}
              color="text_lowContrast"
              className={getSprinkles({ alignSelf: "start" })}
            />
          )}
          {prefix && (
            <Box
              {...(prefixTag && { as: prefixTag })}
              color="ui_primary_base"
              maxWidth={maxWidth}
              textAppearance="prefix_lg"
            >
              {prefix}
            </Box>
          )}
          {title && (
            <Box fontFamily={fontFamily} maxWidth={maxWidth}>
              <Balancer
                {...(titleTag && { as: titleTag })}
                className={getSprinkles({ textAppearance })}
              >
                {title}
              </Balancer>
            </Box>
          )}
          {description && (
            <StoryblokRichText
              isTextBalanced
              width="auto"
              maxWidth={maxWidth}
              text={description}
              className={styles.backdropFilter}
            />
          )}
          {date && (
            <Box className={dateStyle}>
              {datePrefix ? `${datePrefix} ${date}` : date}
            </Box>
          )}

          {(authorDate || author) && (
            <ContentAuthorDate date={authorDate} author={author} />
          )}
        </Box>

        {(prefixLinkIsButtonLink ||
          buttonLinkIsFormHubspotDialog ||
          isButtonLink) && (
          <Box
            display="inline-flex"
            width="max-content"
            paddingBottom="spacing1"
            marginTop="spacing2"
          >
            {prefixLinkIsButtonLink && (
              <ButtonLink
                {...(prefixLink as ButtonLinkProps)}
                shouldHaveArrowIcon={false}
                iconRight={faArrowRightLong}
              />
            )}
            {isButtonLink && (
              <ButtonLink
                marginTop="auto"
                {...(buttonLink as ButtonLinkProps)}
              />
            )}
            {buttonLinkIsFormHubspotDialog && (
              <FormHubspotDialog
                {...(buttonLink as FormHubspotDialogProps)}
                marginTop="auto"
                buttonProps={BUTTON_PROPS}
                // Set default conversionType to "contact_sales" if not provided
                conversionType={
                  (buttonLink as FormHubspotDialogProps).conversionType ||
                  "contact_sales"
                }
              />
            )}
          </Box>
        )}
      </Box>
      {/* Content cover */}
      {coverVideo && (
        <Box
          id={coverVideoContainerID}
          ref={videoContainer}
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
          onPointerMove={handlePointerMove}
          zIndex="2"
          className={`cover-video-container ${styles.coverVideoStyle({
            isSticky,
          })}`}
          style={{
            transform: `translateX(${translate.x}px) translateY(${translate.y}px)`,
          }}
        >
          <StoryblokEditable {...coverVideo}>
            <>
              <EmbedWistia {...coverVideo} />
              <Box
                as="button"
                id="close-video-button"
                aria-label="Close"
                className={styles.coverVideoIconButtonStyle}
                onClick={() => {
                  return closeStickyVideo({ disable: true });
                }}
              >
                <FontAwesomeIcon
                  id="cover-video-close-button"
                  icon={faXmark}
                  className={styles.coverVideoIconStyle}
                />
              </Box>
            </>
          </StoryblokEditable>
        </Box>
      )}
      {!coverVideo && cover?.filename && (
        <Box __flex={coverFlex} id="section-header-cover-image-container">
          {cover && (
            <Image
              image={cover}
              borderRadius="sm"
              marginBottom="spacing3"
              className={styles.getCoverImageStyle({ coverAspectRatio })}
            />
          )}
        </Box>
      )}
      {/* Image */}
      {image?.filename && (
        <Box
          maxWidth="33%"
          width="100%"
          position="relative"
          __flex={infoFlex}
          test-id="section-header-image"
          display={{
            mobile: "none",
            desktop: "block",
          }}
        >
          <Box
            aspectRatio="square"
            className={commonStackedGrid}
            data-motion="mask"
            display="grid"
            justifyItems="center"
            alignItems="center"
            clipPath={mask}
          >
            {videoBlok && (
              <Video
                isHiddenOnMobile
                key={videoBlok._uid}
                // data-motion={ANIMATED.video}
                position="absolute"
                objectFit="cover"
                inset="0"
                preload="auto"
                {...videoBlok}
              />
            )}
          </Box>
          <Box
            height="auto"
            maxWidth="75%"
            objectFit="cover"
            width="auto"
            className={offsetStyle}
          >
            <Image image={image} objectFit="cover" />
          </Box>
        </Box>
      )}
    </Box>
  );
}
