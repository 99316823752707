/**
 * Create an object, indexable by component name, mapped to React components.
 * As our CMS content can arrive in a nested structure, we need a map to be able
 * to dynamically render components.
 *
 * Components that are commonly used can be directly imported. All other components
 * are lazy loaded using `React.lazy()`. (Wrapped with `react-lazily` to handle named
 * exports and simplify adding to index). All components are rendered downstream by
 * `StoryblokComponent`, which wraps them in `React.Suspense`.
 *
 * As gatsby v4 supports React 18 Lazy loading in SSR, the Suspense fallback content
 * shouldn't be rendered in the browser. (Unless something is broken)
 */
import { AccordionRichText } from "~components/AccordionRichText";
import { BlogPostCategories } from "~components/BlogCategories/BlogPostCategories";
import { ButtonLink } from "~components/ButtonLink";
import { Card } from "~components/Card";
import { ContentCallout } from "~components/ContentCallout";
import { CtaCard } from "~components/CtaCard";
import { CtaNewsletter } from "~components/CtaNewsletter";
import { EmbedWistia } from "~components/EmbedWistia";
import { FormHubspotDialog } from "~components/FormHubspotDialog";
import { Grid } from "~components/Grid";
import { HorizontalDivider } from "~components/HorizontalDivider";
import { Image } from "~components/Image";
import { LinkList } from "~components/LinkList";
import { LogoList } from "~components/LogoList";
import { NavCallOut } from "~components/NavCallOut";
import { Quote } from "~components/Quote";
import { SectionHeader } from "~components/SectionHeader";
import { Table } from "~components/Table";
import { TickList } from "~components/TickList";
import { Video } from "~components/Video";
import { VideoPhoneMockup } from "~components/VideoPhoneMockup";
import { VideoYoutube } from "~components/VideoYoutube";

import { AccordionFrequentlyAskedQuestions } from "~sections/AccordionFrequentlyAskedQuestions";
import { AccordionHowItWorks } from "~sections/AccordionHowItWorks";
import LazyCodeSampleWindow from "~sections/CodeSampleWindow/Lazy.CodeSampleWindow";
import { CtaFooter } from "~sections/CtaFooter";
import { GridCards } from "~sections/GridCards";
import { GridImages } from "~sections/GridImages";
import { GridPeople } from "~sections/GridPeople";
import { GridPhoneMockups } from "~sections/GridPhoneMockups";
import { GridStats } from "~sections/GridStats";
import LazyHeroTypewriter from "~sections/HeroTypewriter/Lazy.HeroTypewriter";
import { HeroWithAnimation } from "~sections/HeroWithAnimation";
import { RelationsCards } from "~sections/RelationsCards";
import { RelationsLinkList } from "~sections/RelationsLinkList";
import { RelationsTags } from "~sections/RelationsTags";
import { AccordionAnimationsAddonPage } from "~sections/accordion_animations/AccordionAnimationsAddonPage";
import { AccordionAnimationsHomepage } from "~sections/accordion_animations/AccordionAnimationsHomepage";
import { AccordionAnimationsProductPage } from "~sections/accordion_animations/AccordionAnimationsProductPage";
import LazyCarouselQuotes from "~sections/carousels/CarouselQuotes/Lazy.CarouselQuotes";
import LazyRelationsCarousel from "~sections/carousels/RelationsCarousel/Lazy.RelationsCarousel";

import { AnimatedMaskTitle } from "~animations/AnimatedMaskTitle";
import { AnimatedVideoMaskWithStats } from "~animations/AnimatedVideoMaskWithStats";

import { FormHubspot } from "../FormHubspot";
import { StoryblokRichText } from "../StoryblokRichText";

import type { ComponentMap } from "~types/global.types";

export const STORYBLOK_COMPONENT_MAP: ComponentMap = {
  AccordionAnimationsAddonPage,
  AccordionAnimationsHomepage,
  AccordionAnimationsProductPage,
  AccordionFrequentlyAskedQuestions,
  AccordionHowItWorks,
  AccordionRichText,
  AnimatedMaskTitle,
  AnimatedVideoMaskWithStats,
  ButtonLink,
  blogCategories: BlogPostCategories,
  card: Card,
  cardCta: CtaCard,
  ctaLink: CtaCard,
  contentCallout: ContentCallout,
  ctaNewsletterSignup: CtaNewsletter,
  ctaFooter: CtaFooter,
  formHubspot: FormHubspot,
  FormHubspotDialog,
  grid: Grid,
  gridCards: GridCards,
  gridImages: GridImages,
  gridPeople: GridPeople,
  gridPhoneMockups: GridPhoneMockups,
  gridStats: GridStats,
  gridSvgLogos: LogoList,
  HeroWithAnimation,
  horizontalDivider: HorizontalDivider,
  image: Image,
  linkList: LinkList,
  logoTicker: LogoList,
  quote: Quote,
  relationsCards: RelationsCards,
  relationsLinkList: RelationsLinkList,
  relationsTags: RelationsTags,
  richText: StoryblokRichText,
  sectionHeader: SectionHeader,
  table: Table,
  tickList: TickList,
  video: Video,
  videoPhoneMockup: VideoPhoneMockup,
  VideoYoutube,
  EmbedWistia,
  NavCallOut,

  /**
   * ------------------------------------------------------------------------------
   * Lazy loaded components
   * ------------------------------------------------------------------------------
   */

  sourceCodeWindow: LazyCodeSampleWindow,
  HeroTypewriter: LazyHeroTypewriter,
  carouselQuotes: LazyCarouselQuotes,
  relationsCarousel: LazyRelationsCarousel,
};
